body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #fff;
}

iframe {
  display: none;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.popup::after {
  top: 40px !important;
}

.popupItem {
  font-size: 13px !important;
  padding: 6px 8px !important;
}

.MuiDialog-container .MuiPaper-root {
  background-color: #00133b;
}

.MuiDialogTitle-root {
  color: #ffffff;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
  border-radius: 30px !important;
}

#intro {
  background-image: url("../images/intro.jpeg");
  background-size: cover;
  padding: 60px 50px;
  border-radius: 7px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
}
